import { useAuth0 } from '@auth0/auth0-react';
import { Button, createStyles, makeStyles } from '@material-ui/core';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { getApiKeyList, createApiKey, updateApiKeyStatus, updateApiKeyAttribute, deleteApiKey } from '../utils/api';
import { MainList } from './MainList';

export const ApiKeys = () => {
  const useStyles = makeStyles((theme) =>
    createStyles({
      container: {
        paddingBottom: theme.spacing(4),
      },
      buttonSection: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(2),
      },
      deleteButton: {
        background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
        borderRadius: 3,
        border: 0,
        color: 'white',
        height: 48,
        padding: '0 30px',
        boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
      },
    })
  );
  const classes = useStyles();

  const [accessToken, setAccessToken] = useState('');
  const [loading, setLoading] = useState(true);
  const [keyList, setKeyList] = useState([]);
  const { getAccessTokenSilently } = useAuth0();
  const getKeyList = async () => {
    const token = await getAccessTokenSilently();
    console.log('ttoken: ', token);
    setAccessToken(token);
    const response = await getApiKeyList(token);
    console.log('response: ', response);
    setLoading(false);
    setKeyList(
      response
        .filter((item: any) => item.status !== 'DELETED')
        .map((item: any) => {
          return {
            _id: item.key,
            apiKey: item.key,
            callbackUrl: item.callbackUrl,
            isActive: item.status === 'ACTIVE',
            createdAt: item.createdAt ? moment(item.createdAt).format('YYYY.MM.DD HH:mm:ss') : 'N/A',
            updatedAt: item.updatedAt ? moment(item.updatedAt).format('YYYY.MM.DD HH:mm:ss') : 'N/A',
            note: item.note,
            deleteButton: (
              <Button
                style={{ backgroundColor: item.status === 'DELETED' ? 'grey' : 'red' }}
                onClick={() => handleDeleteButtonClick(token, item.key)}
                variant="contained"
                disabled={item.status === 'DELETED'}
              >
                {item.status === 'DELETED' ? 'DELETED' : 'DELETE'}
              </Button>
            ),
          };
        })
    );
  };
  useEffect(() => {
    getKeyList();
  }, [getAccessTokenSilently]);

  const handleCreateApiKeyButtonClick = async () => {
    await createApiKey(accessToken);
    await getKeyList();
  };

  const handleInputChange = async (id: string, name: string, newValue: boolean | string) => {
    console.log('input change: ', id, name);
    if (name === 'isActive') {
      await updateApiKeyAttribute(accessToken, id, 'status', newValue ? 'ACTIVE' : 'INACTIVE');
    } else if (typeof newValue === 'string') {
      setLoading(true);
      await updateApiKeyAttribute(accessToken, id, name, newValue);
    }
    await getKeyList();
  };

  const handleDeleteButtonClick = async (accessToken: string, id: string) => {
    console.log(id);
    await deleteApiKey(accessToken, id);
    await getKeyList();
  };
  const columnInfo = [
    { name: 'apiKey', label: 'API Key', notSortable: true, style: { minWidth: 150 } },
    { name: 'isActive', label: 'Status', notSortable: true, style: { minWidth: 200 } },
    { name: 'callbackUrl', label: 'Callback URL', notSortable: true, isTextEditable: true, style: { minWidth: 200 } },
    { name: 'note', label: 'Note', notSortable: true, isTextEditable: true, style: { minWidth: 200 } },
    { name: 'createdAt', label: '생성일', notSortable: true, style: { minWidth: 110 } },
    { name: 'updatedAt', label: '수정일', notSortable: true, style: { minWidth: 110 } },
    { name: 'deleteButton', label: '지우기', notSortable: true, style: { minWidth: 200 } },
  ];
  if (loading) {
    return <div>Loading...</div>;
  }
  return (
    <div className={classes.container}>
      <h1>API Key 생성 및 관리</h1>
      {/* <p>{`Bearer ${accessToken}`}</p> */}
      <div className={classes.buttonSection}>
        {accessToken && (
          <Button onClick={handleCreateApiKeyButtonClick} color="secondary" variant="contained">
            API Key 생성하기
          </Button>
        )}
      </div>
      {keyList.length > 0 && (
        <MainList sortable={false} listData={keyList} columnInfo={columnInfo} onInputSubmit={handleInputChange} />
      )}
    </div>
  );
};
