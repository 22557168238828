import '../App.css';
import React, { useEffect, useState } from 'react';
import { getAdminUsageTotalData } from '../utils/api';
import { useAuth0 } from '@auth0/auth0-react';
import BarChartComponent from './BarChart';
import { isAdmin, toCurrencyFormatString, formatMonthToReadable, getMonthOptions } from '../utils/common';
import { Redirect } from 'react-router-dom';

interface AdminData {
  date: number;
  translated_word_count: number;
  call_counts: object;
  price_per_word: number;
  price_per_call_count: number;
  call_count_sum: number;
  day_total: number;
}

const getCurrentMonth = (): string => {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Add 1 to the month since it is zero-based
  const currentMonth = `${year}${month}`;
  return currentMonth;
};

const AdminUsages: React.FC = () => {
  const [accessToken, setAccessToken] = useState('');
  const [loading, setLoading] = useState(false);
  const [adminData, setAdminData] = useState<AdminData[] | null>(null);
  const [selectedMonth, setSelectedMonth] = useState<string>(getCurrentMonth());
  const [monthlyTotal, setMonthlyTotal] = useState<string>('0원');
  const [expectedTotal, setExpectedTotal] = useState<string>('0원');
  const monthOptions = getMonthOptions();

  const { getAccessTokenSilently, user } = useAuth0();

  useEffect(() => {
    fetchAdminData(selectedMonth);
  }, [accessToken]);

  const handleMonthChange = async (event: React.ChangeEvent<HTMLSelectElement>) => {
    const month = event.target.value;
    console.log('handleMonthChange month: ', month);
    setSelectedMonth(month);
    setLoading(true);
    await fetchAdminData(month);
    setLoading(false);
  };

  const fetchAdminData = async (month: string) => {
    try {
      const token = await getAccessTokenSilently();
      setAccessToken(token);
      const adminResponse = await getAdminUsageTotalData(month, accessToken);
      if (adminResponse) {
        setAdminData(adminResponse.usage);
        setMonthlyTotal(toCurrencyFormatString(adminResponse.monthly_total));
        setExpectedTotal(toCurrencyFormatString(adminResponse.expected_total));
      }
    } catch (error) {
      console.log('error: ', error);
    }
  };
  if (!isAdmin(user)) {
    return <Redirect to="/" />;
  }

  if (!adminData) {
    return <p>Loading...</p>;
  }
  return (
    <div>
      <h1>월별 매출</h1>
      <div className="selector-container">
        <select className="apikey-selector" value={selectedMonth} onChange={handleMonthChange}>
          {monthOptions.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
      {loading ? (
        <p>loading...</p>
      ) : (
        <div>
          <h2>
            {formatMonthToReadable(selectedMonth)} 실시간: {monthlyTotal}
          </h2>
          <h2>이번 달 예상 매출: {expectedTotal}</h2>
          {adminData && adminData.length > 0 && (
            <div>
              <BarChartComponent data={adminData} />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default AdminUsages;
