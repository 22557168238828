import React, { useState } from 'react';
import clsx from 'clsx';
import { createStyles, makeStyles, useTheme, Theme } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      maxWidth: 300,
      cursor: 'pointer',
    },
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    chip: {
      margin: 2,
      cursor: 'pointer',
    },
    noLabel: {
      marginTop: theme.spacing(3),
    },
  })
);

const MenuProps = {
  PaperProps: {
    style: {
      height: 700,
      width: 300,
    },
  },
};

export default function MultipleSelect(props: any) {
  const classes = useStyles();

  const handleCheckboxChange = (item: any, checked: boolean) => {
    const index = props.selected.findIndex((elem: any) => elem._id === item._id);
    if (checked && index === -1) {
      props.onCheckboxSelectChange([...props.selected, item]);
    } else if (!checked && index > -1) {
      props.selected.splice(index, 1);
      props.onCheckboxSelectChange([...props.selected]);
    }
  };

  return (
    <FormControl className={classes.formControl}>
      <Select
        disableUnderline
        labelId="mutiple-checkbox-label"
        id="mutiple-checkbox"
        multiple
        value={props.selected}
        input={<Input />}
        renderValue={(selected: any) => (
          <div className={classes.chips}>
            {selected.map((value: any) => (
              <Chip key={value._id} label={value.categoryName} className={classes.chip} />
            ))}
          </div>
        )}
        MenuProps={MenuProps}
      >
        {props.menuList.map((item: any) => {
          // console.log('found: ', props.selected.findIndex((value: any) => value._id === item._id) >= 0);
          // console.log('item: ', item);
          // console.log('props.selected: ', props.selected);
          return (
            <MenuItem key={item._id} value={item}>
              <Checkbox
                onChange={(e: any) => handleCheckboxChange(item, e.target.checked)}
                checked={props.selected.findIndex((value: any) => value._id === item._id) >= 0}
              />
              <ListItemText primary={item.categoryName} />
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}
