import React from 'react';
import { TooltipProps, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
// for recharts v2.1 and above
import { ValueType, NameType } from 'recharts/types/component/DefaultTooltipContent';

interface UsageData {
  date: number;
  price_per_word: number;
  price_per_call_count: number;
  day_total: number;
  translated_word_count: number;
}

interface BarChartProps {
  data: UsageData[];
}

const BarChartComponent: React.FC<BarChartProps> = ({ data }) => {
  console.log('chart data: ', data);
  return (
    <BarChart width={1000} height={600} data={data}>
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="date" />
      <YAxis />
      <Tooltip content={<CustomTooltip />} />
      <Legend />
      <Bar dataKey="day_total" fill="#8884d8" />
    </BarChart>
  );
};

const CustomTooltip = ({ active, payload, label }: TooltipProps<ValueType, NameType>) => {
  if (active && payload && payload.length) {
    const item = payload?.[0].payload;
    return (
      <div className="custom-tooltip">
        <p className="desc">번역된 단어 수: {item.translated_word_count}</p>
        {Object.keys(item.call_counts).map((key) => {
          return (
            <p className="desc">
              {key}: {item.call_counts[key]}
            </p>
          );
        })}
      </div>
    );
  }
  return null;
};

export default BarChartComponent;
