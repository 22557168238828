import {
  createStyles,
  FormControlLabel,
  IconButton,
  InputAdornment,
  makeStyles,
  OutlinedInput,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Theme,
} from '@material-ui/core';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import DoneIcon from '@material-ui/icons/Done';
import CancelIcon from '@material-ui/icons/Cancel';
import { withStyles } from '@material-ui/styles';
import React, { useEffect, useState } from 'react';
import MultipleSelector from './MultipleSelector';

interface Pagination {
  has_next?: boolean;
  has_prev?: boolean;
  page_no?: number;
  page_size: number;
  total_pages?: number;
  total_rows?: number;
}

export interface ListParam {
  page_size?: number;
  page_no?: number;
  start_date?: number | null;
  end_date?: number | null;
  keyword?: string;
}

interface MainListProps {
  listData: any;
  columnInfo: Array<any>;
  selectedItems?: any;
  sortable?: boolean;
  tabs?: boolean;
  buttons?: Array<any>;
  pagination?: Pagination;
  multipleSelectList?: any;
  onChangeDate?: (date: number | null) => void;
  noPagination?: boolean;
  onSortToggle?: (col: any) => void;
  onInputSubmit?: (id: string, name: string, value: any) => void;
  onMultipleSelectCheckboxChange?: (id: string, name: string, value: any) => void;
}

export const MainList = (props: MainListProps) => {
  const [selectedColumnIndex, setSelectedColumnIndex] = useState(0);
  const [selectedCell, setSelectedCell] = useState<any>(null);

  let items = props.listData || [];
  const StyledTableCell = withStyles((theme: Theme) =>
    createStyles({
      head: {
        backgroundColor: '#fafafa',
        color: '#212121',
        fontWeight: 500,
      },
      body: {
        fontSize: 14,
        fontWeight: 'normal',
      },
    })
  )(TableCell);

  const useListStyles = makeStyles((theme: Theme) => ({
    tableContainer: {
      paddingTop: 0,
      overflowX: 'initial',
      overflowY: 'scroll',
      maxHeight: '80vh',
    },
    table: {
      // tableLayout: 'auto',
    },

    tableHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      margin: 0,
      height: 50,
    },

    headerLabel: {
      display: 'flex',
      alignItems: 'center',
      '&:hover:not(.Mui-disabled)': {
        cursor: 'pointer',
      },
    },

    topRightSection: {
      display: 'flex',
      alignItems: 'center',
      '& > *': {
        marginLeft: theme.spacing(2),
      },
    },
  }));

  const handleToggleSortClick = (col: any, index: number) => {
    if (props.onSortToggle) {
      props.onSortToggle(col);
    }
    setSelectedColumnIndex(index);
  };

  const handleMultipleSelectable = (col: any, index: number) => {
    // setSelectedColumnIndex(index);
    alert('카테고리 필터링 기능은 추후에 구현 예정입니다.');
  };

  const handleCellClick = (row: any, col: any) => {
    setSelectedCell(
      col.isTextEditable
        ? {
            _id: row._id,
            name: col.name,
            text: row[col.name],
          }
        : null
    );
  };
  const handleCancel = (e: any) => {
    e.stopPropagation();
    setSelectedCell(null);
  };

  const handleSave = (e: any) => {
    e.stopPropagation();
    console.log('selectedCell: ', selectedCell);
    if (props.onInputSubmit) {
      props.onInputSubmit(selectedCell._id, selectedCell.name, selectedCell.text);
    }
    setSelectedCell(null);
  };

  const handleInputChange = (e: any) => {
    selectedCell.text = e.target.value;
    setSelectedCell(selectedCell);
  };

  const classes = useListStyles();

  return (
    <TableContainer className={classes.tableContainer} component={Paper} elevation={3}>
      <Table stickyHeader className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell align="left">
              <div className={classes.headerLabel}></div>
            </StyledTableCell>
            {props.columnInfo.map((col, index) => {
              let upDownArrow = null;
              if (!col.notSortable && selectedColumnIndex === index) {
                upDownArrow = col.isAscending ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />;
              }
              return (
                <StyledTableCell
                  onClick={() =>
                    col.isMultipleSelectable ? handleMultipleSelectable(col, index) : handleToggleSortClick(col, index)
                  }
                  key={index}
                  align="left"
                >
                  <div className={classes.headerLabel}>
                    {col.label} {!col.isMultipleSelectable && upDownArrow}
                  </div>
                </StyledTableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((row: any, rowIndex: number) => {
            return (
              <TableRow style={{ background: rowIndex % 2 ? '#f6f6f6' : 'white' }} key={rowIndex.toString()}>
                <TableCell align="left">{rowIndex + 1}</TableCell>
                {props.columnInfo.map((col, index) => {
                  if (typeof row[col.name] === 'boolean') {
                    return (
                      <TableCell onClick={(e) => handleCellClick(row, col)} key={index} style={col.style} align="left">
                        <FormControlLabel
                          style={{ marginRight: 0 }}
                          control={
                            <Switch
                              checked={!!row[col.name]}
                              onChange={(e: any) => {
                                if (props.onInputSubmit) {
                                  props.onInputSubmit(row._id, col.name, e.target.checked);
                                }
                              }}
                              name="verified"
                              color="secondary"
                            />
                          }
                          label={!!row[col.name] ? 'ACTIVE' : 'INACTIVE'}
                        />
                      </TableCell>
                    );
                  } else if (col.isMultipleSelectable) {
                    return (
                      <TableCell key={index} style={col.style} align="left">
                        <MultipleSelector
                          selected={row[col.name]}
                          menuList={props.multipleSelectList}
                          label={col.label}
                          onCheckboxSelectChange={(selected: any) => {
                            if (props.onMultipleSelectCheckboxChange) {
                              props.onMultipleSelectCheckboxChange(row._id, col.name, selected);
                            }
                          }}
                        />
                      </TableCell>
                    );
                  }
                  const isSelected = selectedCell?._id === row._id && selectedCell?.name === col.name;
                  return (
                    <TableCell
                      onClick={(e) => handleCellClick(row, col)}
                      key={index}
                      style={{ ...col.style, cursor: col.isTextEditable ? 'pointer' : 'inherit' }}
                      align="left"
                    >
                      {isSelected ? (
                        <OutlinedInput
                          id={row._id}
                          autoFocus
                          multiline
                          defaultValue={col.name === 'tags' ? row[col.name].join() : row[col.name]}
                          name={col.name}
                          onChange={handleInputChange}
                          endAdornment={
                            <>
                              <InputAdornment onClick={handleCancel} position="end">
                                <IconButton style={{ padding: 0 }}>
                                  <CancelIcon />
                                </IconButton>
                              </InputAdornment>
                              <InputAdornment onClick={handleSave} position="end">
                                <IconButton style={{ padding: 0 }}>
                                  <DoneIcon />
                                </IconButton>
                              </InputAdornment>
                            </>
                          }
                          aria-describedby="outlined-weight-helper-text"
                          inputProps={{ style: { fontSize: 12 } }}
                          labelWidth={0}
                        />
                      ) : (
                        <>{col.name === 'tags' ? row[col.name]?.join() || [] : row[col.name]}</>
                      )}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>

        {props.buttons && (
          <TableBody>
            <TableRow>
              <TableCell colSpan={100}>{props.buttons.map((buttonElement: any) => buttonElement)}</TableCell>
            </TableRow>
          </TableBody>
        )}
      </Table>
    </TableContainer>
  );
};
