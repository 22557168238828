import { ListItemProps } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { NotInterested, PeopleAlt } from '@material-ui/icons';
import DescriptionIcon from '@material-ui/icons/Description';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import material from '@material-ui/icons/Description';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import BarChartIcon from '@material-ui/icons/BarChart';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import ReceiptIcon from '@material-ui/icons/Receipt';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import clsx from 'clsx';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ExtendedTheme } from '../theme';
import { isAdmin } from '../utils/common';

const useStyles = makeStyles((theme: ExtendedTheme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: theme.drawerWidth,
      width: `calc(100% - ${theme.drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: 36,
    },
    hide: {
      display: 'none',
    },
    drawer: {
      width: theme.drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
    },
    drawerOpen: {
      width: theme.drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9) + 1,
      },
    },
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
  })
);
const menu = [
  { label: 'API Keys', icon: <VpnKeyIcon />, route: '/keys' },
  { label: '실시간 사용량', icon: <BarChartIcon />, route: '/' },
  { label: '인보이스', icon: <ReceiptIcon />, route: '/invoices' },
  { label: 'Incident History', icon: <PriorityHighIcon />, route: '/incidents' },
];
const managementList: any[] = [
  {
    label: 'API 문서',
    icon: <DescriptionIcon />,
    link: 'https://app.swaggerhub.com/apis/twigfarm/letr-public/0.1.71',
  },
];
const adminMenu = [{ label: 'Admin Revenue', icon: <MonetizationOnIcon />, route: '/admin/revenue' }];
const ListItemLink = (props: ListItemProps<'a', { button?: true }>) => {
  return <ListItem button component="a" {...props} />;
};

export const LeftDrawer = (props: any) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  console.log(props.user);
  console.log('isAdmin: ', isAdmin(props.user));
  const classes = useStyles();
  const history = useHistory();

  const handleSelect = (index: number, route: string) => {
    setSelectedIndex(index);
    history.push(route);
  };
  return (
    <Drawer
      variant="permanent"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: props.isOpen,
        [classes.drawerClose]: !props.isOpen,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: props.isOpen,
          [classes.drawerClose]: !props.isOpen,
        }),
      }}
    >
      <div className={classes.toolbar}>
        <IconButton onClick={props.onToggleClick}>{<ChevronLeftIcon />}</IconButton>
      </div>
      <Divider />
      <List>
        {menu.map((item, index) => (
          <ListItemLink key={item.label} onClick={() => handleSelect(index, item.route)}>
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText primary={item.label} />
          </ListItemLink>
        ))}
      </List>
      <Divider />
      <List>
        {managementList.map((item, index) => (
          <ListItemLink key={item.label} onClick={() => window.open(item.link, '_blank')}>
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText primary={item.label} />
          </ListItemLink>
        ))}
      </List>
      <Divider />
      <List>
        {isAdmin(props.user) &&
          adminMenu.map((item, index) => (
            <ListItemLink key={item.label} onClick={() => handleSelect(index, item.route)}>
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.label} />
            </ListItemLink>
          ))}
      </List>
    </Drawer>
  );
};
