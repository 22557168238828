export const isAdmin = (user: any) => {
  const roles = user['https://api.letr.ai/roles'];
  if (!roles) {
    return false;
  }
  return roles.some((item: string) => item === 'admin');
};

export const toCurrencyFormatString = (inputNmber: number) => {
  return inputNmber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + '원';
};

export const numberToCommaSeparatedString = (inputNmber: number) => {
  return inputNmber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const formatMonthToReadable = (month: String) => {
  return `${month.substring(0, 4)}년 ${month.substring(4)}월`;
};

export const formatDateTime = (datetime: String) => {
  return `${datetime.substring(0, 10)} ${datetime.substring(11, 19)}`;
};

interface MonthOption {
  value: string;
  label: string;
}

export const getMonthOptions = (): MonthOption[] => {
  const options: MonthOption[] = [];
  const currentDate = new Date();

  for (let i = 0; i < 12; i++) {
    const date = new Date(currentDate.getFullYear(), currentDate.getMonth() - i, 1);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');

    options.push({ value: `${year}${month}`, label: `${year}/${month}` });
  }

  return options;
};
