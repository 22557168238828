import { useAuth0 } from '@auth0/auth0-react';
import { Button } from '@material-ui/core';
import React from 'react';

const LogoutButton = () => {
  const { logout } = useAuth0();

  return (
    <Button color="inherit" onClick={() => logout({ returnTo: 'https://www.letr.ai/' })}>
      Logout
    </Button>
  );
};

export default LogoutButton;
