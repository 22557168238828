import '../App.css';
import React, { useEffect, useState } from 'react';

import { getAdminUsageTotalData, getApiKeyList, getMonthlyUsageData } from '../utils/api';
import { toCurrencyFormatString, formatMonthToReadable } from '../utils/common';
import { useAuth0 } from '@auth0/auth0-react';
import BarChartComponent from './BarChart';

export interface ApiKey {
  _id: string;
  key: string;
}

export interface MonthOption {
  value: string;
  label: string;
}

interface UsageData {
  date: number;
  price_per_word: number;
  price_per_call_count: number;
  day_total: number;
  translated_word_count: number;
}

const getCurrentMonth = (): string => {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Add 1 to the month since it is zero-based
  const currentMonth = `${year}${month}`;
  return currentMonth;
};

const getMonthOptions = (): MonthOption[] => {
  const options: MonthOption[] = [];
  const currentDate = new Date();

  for (let i = 0; i < 12; i++) {
    const date = new Date(currentDate.getFullYear(), currentDate.getMonth() - i, 1);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');

    options.push({ value: `${year}${month}`, label: `${year}/${month}` });
  }

  return options;
};

const Usages: React.FC = () => {
  const [accessToken, setAccessToken] = useState('');
  const [loading, setLoading] = useState(false);
  const [apiKeyList, setApiKeyList] = useState<ApiKey[] | null>(null);
  const [selectedApiKey, setSelectedApiKey] = useState<string | undefined>(undefined);
  const [usageData, setUsageData] = useState<UsageData[] | null>(null);
  const [selectedMonth, setSelectedMonth] = useState<string>(getCurrentMonth());
  const [monthlyTotal, setMonthlyTotal] = useState<string>('0원');
  const [expectedTotal, setExpectedTotal] = useState<string>('0원');
  const monthOptions = getMonthOptions();
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    fetchApiKeyList();
  }, [accessToken]);

  const fetchApiKeyList = async () => {
    try {
      const token = await getAccessTokenSilently();
      setAccessToken(token);
      setApiKeyList(await getApiKeyList(accessToken));
    } catch (error) {
      console.error('Error fetching API key list:', error);
    }
  };

  const handleApiKeyChange = async (event: React.ChangeEvent<HTMLSelectElement>) => {
    const apiKey = event.target.value;
    setSelectedApiKey(apiKey);
    setLoading(true);
    await fetchUsageData(apiKey, selectedMonth);
    setLoading(false);
  };

  const handleMonthChange = async (event: React.ChangeEvent<HTMLSelectElement>) => {
    const month = event.target.value;
    console.log('handleMonthChange month: ', month);
    setSelectedMonth(month);
    setLoading(true);
    await fetchUsageData(selectedApiKey, month);
    setLoading(false);
  };

  const fetchUsageData = async (apiKey: string | undefined, month: string) => {
    if (apiKey) {
      try {
        console.log('fetchUsageData month: ', month);
        const response = await getMonthlyUsageData(month, apiKey, accessToken);
        setUsageData(response.usage);
        setMonthlyTotal(toCurrencyFormatString(response.monthly_total));
        setExpectedTotal(toCurrencyFormatString(response.expected_total));
      } catch (error) {
        console.log('error: ', error);
      }
    } else {
      setUsageData(null);
    }
  };
  console.log('usageData: ', usageData);

  if (!apiKeyList) {
    return <p>Loading...</p>;
  }
  return (
    <div className="chart-container">
      <h1>실시간 사용량</h1>
      <div className="selector-container">
        <select className="apikey-selector" value={selectedApiKey} onChange={handleApiKeyChange}>
          <option value="">Select Tenent(API key)</option>
          {apiKeyList.map((apiKey) => (
            <option key={apiKey._id} value={apiKey.key}>
              {apiKey.key}
            </option>
          ))}
        </select>
        <select className="yearmonth-selector" value={selectedMonth} onChange={handleMonthChange}>
          {monthOptions.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
      {loading ? (
        <p>loading...</p>
      ) : (
        <div>
          <h2>
            {formatMonthToReadable(selectedMonth)}: {monthlyTotal}
          </h2>
          <h2>이번 달 예상 요금: {expectedTotal}</h2>
          {usageData && (
            <div>
              <BarChartComponent data={usageData} />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Usages;
