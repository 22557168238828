import { useAuth0 } from '@auth0/auth0-react';
import { Link } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import MenuIcon from '@material-ui/icons/Menu';
import clsx from 'clsx';

import { isAdmin } from '../utils/common';
import { ExtendedTheme } from '../theme';
import LogoutButton from './LogoutButton';

const useStyles = makeStyles((theme: ExtendedTheme) =>
  createStyles({
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: theme.drawerWidth,
      width: `calc(100% - ${theme.drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    title: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: 36,
    },
    hide: {
      display: 'none',
    },
  })
);

export const Header = (props: any) => {
  const { isAuthenticated, user } = useAuth0();
  const classes = useStyles();
  return (
    <AppBar
      position="fixed"
      className={clsx(classes.appBar, {
        [classes.appBarShift]: props.isOpen,
      })}
    >
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={props.onToggleClick}
          edge="start"
          className={clsx(classes.menuButton, {
            [classes.hide]: props.isOpen,
          })}
        >
          <MenuIcon />
        </IconButton>

        <Typography variant="h6" className={classes.title}>
          <Link href="/" color="inherit">
            {isAdmin(user) ? 'LETR Console 관리자 계정' : 'LETR Console'}
          </Link>
        </Typography>
        {isAuthenticated && <LogoutButton />}
      </Toolbar>
    </AppBar>
  );
};
