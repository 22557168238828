import axios, { AxiosRequestConfig } from 'axios';
import { accessToken } from './auth';

const restApi = axios.create({
  baseURL: process.env.REACT_APP_HOST_ENV === 'development' ? 'https://api.dev.letr.ai' : 'https://api.letr.ai',
});

const getHeader = () => {
  return { Authorization: `Bearer ${accessToken}` };
};

restApi.interceptors.request.use(async (config: AxiosRequestConfig) => {
  console.log('accessToken: ', accessToken);
  // config.headers = getHeader();
  return config;
});

export const uploadFile = async (filename: string, formData: FormData, apiKey: string) => {
  console.log('apiKey: ', apiKey);
  const url = '/translate-large-file';
  const getUrlResponse = await restApi.get(url, {
    params: {
      file_name: encodeURIComponent(filename),
      source_language_code: 'en',
      target_language_code: 'ko',
      model: 'cust',
      dictionaries: "['Xdh4aiHLaXWsSWvSM', 'xP9zCZyBJsJHTaSEM']",
    },
    headers: {
      'x-api-key': apiKey,
    },
  });
  const config = {
    headers: {
      'content-type': 'multipart/form-data',
    },
  };
  const response = await restApi.put(getUrlResponse.data.upload_file_url, formData, config);
  return response.data;
};

export const getFailureList = async () => {
  const response = await restApi.get('/health-check-failures');
  console.log('response: ', response);
  return response.data;
};

export const getApiKeyList = async (accessToken: string) => {
  if (!accessToken) {
    return null;
  }
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
  const response = await restApi.get('/keys', config);
  console.log('getApiKeyList: ', response.data);
  return response.data.apiKeyList;
};

export const getMonthlyUsageData = async (month: string, apiKey: string, accessToken: string) => {
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    params: {
      month,
      api_key: apiKey,
    },
  };
  const response = await restApi.get('/console/usages', config);
  console.log('getMonthlyUsageData: ', response.data);
  return response.data;
};

export const getInvoiceData = async (month: string, apiKey: string, accessToken: string) => {
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    params: {
      month,
      api_key: apiKey,
    },
  };
  const response = await restApi.get('/console/invoices', config);
  console.log('getInvoiceData: ', response.data);
  return response.data;
};

export const getAdminUsageTotalData = async (month: string, accessToken: string) => {
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    params: {
      month,
    },
  };
  const response = await restApi.get('/console/admin/usage-total', config);
  console.log('getAdminUsageTotalData: ', response.data);
  return response.data;
};

export const createApiKey = async (accessToken: string) => {
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
  const response = await restApi.post('/keys', {}, config);
  console.log('createApiKey: ', response.data);
  return response.data;
};

export const updateApiKeyStatus = async (accessToken: string, id: string, status: string) => {
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
  const response = await restApi.put(`/keys/${id}?status=${status}`, {}, config);
  console.log('updateApiKeyStatus: ', response.data);
  return response.data;
};

export const updateApiKeyAttribute = async (accessToken: string, id: string, name: string, newValue: string) => {
  console.log('name: ', name);
  console.log('newValue: ', newValue);

  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
  const response = await restApi.put(`/keys/${id}?${name}=${newValue}`, {}, config);
  console.log('updateApiKeyAttribute: ', response.data);
  return response.data;
};

export const deleteApiKey = async (accessToken: string, id: string) => {
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
  const response = await restApi.delete(`/keys/${id}`, config);
  console.log('deleteApiKey: ', response.data);
  return response.data;
};
