import { Button, createStyles, makeStyles } from '@material-ui/core';
import { useAuth0 } from '@auth0/auth0-react';

const LoginButton = () => {
  const { loginWithRedirect } = useAuth0();
  return (
    <Button color="primary" size="large" variant="contained" onClick={() => loginWithRedirect({})}>
      <b>회원가입/로그인 하러가기</b>
    </Button>
  );
};

export default LoginButton;
