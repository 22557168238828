import '../App.css';
import '../Incidents.css';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { getFailureList } from '../utils/api';

interface FailureData {
  id: number;
  failedAt: number;
  endpoint: string;
  status?: string;
}
export const Incidents = () => {
  const [failureData, setFailureData] = useState<FailureData[]>([]);
  useEffect(() => {
    fetchFailures();
  }, []);

  const fetchFailures = async () => {
    setFailureData(await getFailureList());
  };

  console.log('failureData: ', failureData);
  return (
    <div className="incident-container">
      <h1>Incidents History</h1>
      <div>
        {failureData?.map((item) => {
          return (
            <div className="item-container" key={item.id}>
              <div className="datetime">{moment(item.failedAt).format()}</div>
              <hr />
              <div>
                <b>Current status: </b>
                {item.status || 'failed'}
              </div>
              <div className="endpoint">
                <div>{item.endpoint} not accessible</div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
