import { Auth0Provider } from '@auth0/auth0-react';
import { CssBaseline } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { theme } from './theme';

ReactDOM.render(
  <React.StrictMode>
    <CssBaseline />
    <ThemeProvider theme={{ ...theme }}>
      <Auth0Provider
        domain="accounts.letr.ai"
        audience="https://api.letr.ai"
        scope="read:current_user update:current_user_metadata read:roles"
        clientId={
          process.env.REACT_APP_HOST_ENV === 'development' ? 'EoSC8A8uofRffjubouXB5Qe5igOSmadz' : 'D75hPom86Cfb0nK2ht6HXeJEVOtuCeI7'
        }
        redirectUri={window.location.origin}
      >
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Auth0Provider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
