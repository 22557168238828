import '../App.css';
import '../Invoice.css';
import React, { useEffect, useState } from 'react';

import { getApiKeyList, getInvoiceData } from '../utils/api';
import { useAuth0 } from '@auth0/auth0-react';
import {
  formatDateTime,
  toCurrencyFormatString,
  numberToCommaSeparatedString,
  formatMonthToReadable,
  getMonthOptions,
} from '../utils/common';

export interface ApiKey {
  _id: string;
  key: string;
}

export interface MonthOption {
  value: string;
  label: string;
}

const getLastMonth = (): string => {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth()).padStart(2, '0'); // Add 1 to the month since it is zero-based
  const currentMonth = `${year}${month}`;
  return currentMonth;
};

export const Invoice = () => {
  const [accessToken, setAccessToken] = useState('');
  const [apiKeyList, setApiKeyList] = useState<ApiKey[] | null>(null);
  const [loading, setLoading] = useState(false);
  const [selectedApiKey, setSelectedApiKey] = useState<string | undefined>(undefined);
  const [invoice, setInvoice] = useState<InvoiceData | null>(null);
  const [selectedMonth, setSelectedMonth] = useState<string>(getLastMonth());
  const monthOptions = getMonthOptions();

  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    fetchApiKeyList();
  }, [accessToken]);

  const fetchApiKeyList = async () => {
    try {
      const token = await getAccessTokenSilently();
      setAccessToken(token);
      setApiKeyList(await getApiKeyList(accessToken));
    } catch (error) {
      console.error('Error fetching API key list:', error);
    }
  };

  const handleApiKeyChange = async (event: React.ChangeEvent<HTMLSelectElement>) => {
    const apiKey = event.target.value;
    setSelectedApiKey(apiKey);
    setLoading(true);
    await fetchInvoiceData(apiKey, selectedMonth);
    setLoading(false);
  };

  const handleMonthChange = async (event: React.ChangeEvent<HTMLSelectElement>) => {
    const month = event.target.value;
    console.log('handleMonthChange month: ', month);
    setSelectedMonth(month);
    setLoading(true);
    await fetchInvoiceData(selectedApiKey, month);
    setLoading(false);
  };

  const fetchInvoiceData = async (apiKey: string | undefined, month: string) => {
    if (apiKey) {
      try {
        console.log('fetchInvoiceData month: ', month);
        const response = await getInvoiceData(month, apiKey, accessToken);
        setInvoice(response.invoice);
      } catch (error) {
        console.log('error: ', error);
      }
    } else {
      setInvoice(null);
    }
  };

  if (!apiKeyList) {
    return <p>Loading...</p>;
  }

  return (
    <div className="chart-container">
      <h1>인보이스</h1>
      <div className="selector-container">
        <select className="apikey-selector" value={selectedApiKey} onChange={handleApiKeyChange}>
          <option value="">Select Tenent(API key)</option>
          {apiKeyList.map((apiKey) => (
            <option key={apiKey._id} value={apiKey.key}>
              {apiKey.key}
            </option>
          ))}
        </select>
        <select className="yearmonth-selector" value={selectedMonth} onChange={handleMonthChange}>
          {monthOptions.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
      {loading ? <p>loading...</p> : <InvoiceTable invoice={invoice} />}
    </div>
  );
};

interface InvoiceData {
  _id: string;
  translated_word_count: number;
  api_key: string;
  user_id: string;
  call_counts: {
    [key: string]: number;
  };
  price_per_word: number;
  price_per_call_count: number;
  call_count_sum: number;
  is_paid: boolean;
  year_month: string;
  total: number;
  createdAt: string;
  updatedAt: string;
}

type Props = {
  invoice: InvoiceData | null;
};
const InvoiceTable = ({ invoice }: Props) => {
  if (!invoice) {
    return <p>해당월은 사용량이 없거나 Invoice가 아직 준비되지 않았습니다.</p>;
  }
  return (
    <div className="invoice-container">
      <h1>Invoice Details</h1>
      <table>
        <tbody>
          <tr>
            <td>Invoice 번호:</td>
            <td>{invoice._id}</td>
          </tr>
          <tr>
            <td>Invoice 연월:</td>
            <td>{formatMonthToReadable(invoice.year_month)}</td>
          </tr>
          <tr>
            <td>Invoice 생성일:</td>
            <td>{formatDateTime(invoice.createdAt)}</td>
          </tr>
          <tr>
            <td>API Key:</td>
            <td>{invoice.api_key}</td>
          </tr>
          <tr>
            <td>User ID:</td>
            <td>{invoice.user_id}</td>
          </tr>
          <tr>
            <td>청구금액:</td>
            <td>{toCurrencyFormatString(invoice.total)}</td>
          </tr>

          <tr>
            <td>결제 완료 여부:</td>
            <td>{invoice.is_paid ? 'Yes' : 'No'}</td>
          </tr>

          <tr>
            <td>API 호출 내역:</td>
            <td>
              <ul>
                {Object.entries(invoice.call_counts).map(([key, value]) => (
                  <li key={key}>
                    {key}: {value}
                  </li>
                ))}
              </ul>
            </td>
          </tr>
          <tr>
            <td>API 호출 합계:</td>
            <td>{numberToCommaSeparatedString(invoice.call_count_sum)}</td>
          </tr>
          <tr>
            <td>API 호출 당 가격:</td>
            <td>{toCurrencyFormatString(invoice.price_per_call_count)}</td>
          </tr>
          <tr>
            <td>번역된 단어의 수:</td>
            <td>{numberToCommaSeparatedString(invoice.translated_word_count)}</td>
          </tr>
          <tr>
            <td>단어당 가격:</td>
            <td>{toCurrencyFormatString(invoice.price_per_word)}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
