import { useAuth0 } from '@auth0/auth0-react';
import { useState } from 'react';
import { Route, RouteProps, Switch } from 'react-router-dom';
import LoginButton from './components/LoginButton';
import { Temp } from './components/Temp';
import { Incidents } from './components/Incidents';
// import { UploadFile } from './components/UploadFile';
import AdminUsages from './components/AdminUsages';
import { Header } from './components/Header';
import { LeftDrawer } from './components/LeftDrawer';
import { createStyles, makeStyles } from '@material-ui/core';
import { ExtendedTheme } from './theme';
import { ApiKeys } from './components/ApiKeys';
import { Invoice } from './components/Invoice';
import Usages from './components/Usages';

const ProtectedRoute = (routeProps: RouteProps) => {
  const useStyles = makeStyles((theme: ExtendedTheme) =>
    createStyles({
      root: {
        display: 'flex',
        alignItems: 'flex-start',
        width: `calc(100% - ${theme.drawerWidth}px)`,
      },
      main: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
      },
      loginButtonContainer: {
        display: 'block',
        margin: '64px auto',
      },
      toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
      },
    })
  );

  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const { isLoading, user, loginWithRedirect, isAuthenticated, getAccessTokenSilently } = useAuth0();

  const toggleDrawerOpen = () => {
    setOpen(!open);
  };

  if (isLoading) {
    <h1>Loading...</h1>;
  }
  if (user && isAuthenticated) {
    return (
      <div className={classes.root}>
        <Header onToggleClick={toggleDrawerOpen} isOpen={open} />
        <LeftDrawer onToggleClick={toggleDrawerOpen} isOpen={open} user={user} />
        <main className={classes.main}>
          <div className={classes.toolbar} />
          <p>Release Version: {process.env.REACT_APP_RELEASE_TIME || 'DEV'}</p>
          <Route {...routeProps} />
        </main>
      </div>
    );
  } else {
    return (
      <div className={classes.loginButtonContainer}>
        <h1>LETR API 관리 콘솔</h1>
        <LoginButton />
      </div>
    );
  }
};

export const routes = () => {
  return (
    <Switch>
      <Route exact path="/temp">
        <Temp />
      </Route>
      <Route exact path="/incidents">
        <Incidents />
      </Route>
      <ProtectedRoute exact path="/">
        <Usages />
      </ProtectedRoute>
      <ProtectedRoute exact path="/invoices">
        <Invoice />
      </ProtectedRoute>
      <ProtectedRoute exact path="/keys">
        <ApiKeys />
      </ProtectedRoute>
      {/* <ProtectedRoute exact path="/upload">
        <UploadFile />
      </ProtectedRoute> */}
      <ProtectedRoute exact path="/admin/revenue">
        <AdminUsages />
      </ProtectedRoute>
    </Switch>
  );
};
function useStyles() {
  throw new Error('Function not implemented.');
}
